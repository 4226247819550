.App {
  padding: 10px 0;
  text-align: center;
   background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
  
  
}

.sub-1{
 width:65%; overflow-y: "auto";
 margin-right: 5%;
 padding:20px;
}

.sub-2{
   padding:20px;
  width:25%;
  margin-right:5%;
  display: flex;
  flex-direction: column;
  gap:10px;
  border:1px solid white;
  border-radius:10px;
  height:38vh;
  margin-top: 50px;
}
@media (max-width: 780px) {
 .App{
  flex-direction: column;
  overflow:auto;
 }

 .sub-1{
  width: 90%;
 }
   

 .sub-2{
  display: none;
 width:90%;
 text-align: initial;
 border: 0px;
 height: fit-content;
 }
  }
a { color: inherit; } 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-card-head-title{
  flex:none !important;
}
.ant-tabs-nav-more{
  color:white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
